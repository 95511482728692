import { UIFriendlyMessage, type IUIFriendlyMessageJSON } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import type { SyncType } from 'o365.pwa.types.ts';

export interface PropertyConfig {
    Name: string,
    Type: 'Lookup' | 'DatePicker' | 'Field' | 'OrgUnit' | 'Object' | 'TimePicker';
    ViewName?: string;
    Columns?: string;
    DisplayMember?: string;
    ValueMember?: string;
    SortOrder?: string;
    Multiselect?: boolean;
    RestrictToContext?: boolean;
}


export enum SyncStatus {
    PendingStart = 0,
    PreSyncCleanupStarted = 1,
    Syncing = 2,
    SyncingWithWarnings = 3,
    SyncingWithErrors = 4,
    SyncingCompletedAwaitingCleanup = 5,
    SyncingCompletedStartedCleaning = 6,
    SyncingComplete = 7,
    SyncingCompleteWithWarnings = 8,
    SyncingCompleteWithErrors = 9,
    SyncingCanceled = 10,
}

export interface IStepSyncProgressJSON {
    syncType?: SyncType;
    syncStatus: number;
    vueComponentName: string;
    vueComponentImportCallback: () => Promise<any>;
    title?: string;
    uiFriendlyMessages: Array<IUIFriendlyMessageJSON>;
    errors: Array<string>;
    syncStarted?: string;
    syncEnd?: string;
    customData?: { [key: string]: any };
}

export interface IStepSyncProgressOptions {
    syncType?: SyncType;
    title?: string;
    vueComponentName: string;
    vueComponentImportCallback: () => Promise<any>;
    syncStatus?: number;
    uiFriendlyMessages?: Array<IUIFriendlyMessageJSON>;
    errors?: Array<string>;
    syncStarted?: string;
    syncEnd?: string;
    customData?: { [key: string]: any };
}

export abstract class StepSyncProgress {
    private _syncStatus: SyncStatus = SyncStatus.PendingStart;

    public readonly vueComponentName: string;
    public readonly vueComponentImportCallback: () => Promise<any>;

    public readonly stepsProgress?: StepSyncProgress[];

    public syncType?: SyncType;
    public title?: string;
    public uiFriendlyMessages: Array<UIFriendlyMessage> = new Array<UIFriendlyMessage>();
    public errors: Array<Error> = new Array<Error>();
    public syncStarted: Date | undefined;
    public syncEnd: Date | undefined;
    public customData: { [key: string]: any } = {};

    public get syncStatus(): SyncStatus {
        return this._syncStatus;
    }

    public get hasError(): boolean {
        return this.errors.length > 0;
    }

    public set syncStatus(newValue: SyncStatus) {
        switch (newValue) {
            case SyncStatus.Syncing:
                this.syncStarted = new Date();
                break;
            case SyncStatus.SyncingComplete:
            case SyncStatus.SyncingCanceled:
            case SyncStatus.SyncingCompleteWithWarnings:
            case SyncStatus.SyncingCompleteWithErrors:
                this.syncEnd = new Date();
                break;
            case SyncStatus.PendingStart:
            case SyncStatus.SyncingWithWarnings:
            case SyncStatus.SyncingWithErrors:
            case SyncStatus.SyncingCompletedAwaitingCleanup:
        }

        // Make sure that sync status does not go down sync level
        if (newValue < this._syncStatus) {
            return;
        }

        this._syncStatus = newValue;
    }

    // abstract get progressStatusTotal(): number;
    abstract get progressStatusPending(): number;
    abstract get progressStatusSuccess(): number;
    abstract get progressStatusError(): number;

    get progressStatusRecordsCompleted(): number {
        return 0;
    }

    get progressStatusRecordsToDownload(): number {
        return 0;
    }

    get progressStatusFilesCompleted(): number {
        return 0;
    }

    get progressStatusFilesToDownload(): number {
        return 0;
    }

    constructor(options: IStepSyncProgressOptions | IStepSyncProgressJSON) {
        this.syncType = options.syncType;
        this.title = options.title;
        this.vueComponentName = options.vueComponentName;
        this.vueComponentImportCallback = options.vueComponentImportCallback;

        options.syncStatus && (this.syncStatus = options.syncStatus);
        options.uiFriendlyMessages && (this.uiFriendlyMessages = options.uiFriendlyMessages.map((uiFriendlyMessageJson) => new UIFriendlyMessage(uiFriendlyMessageJson.uiFriendlyMessageType, uiFriendlyMessageJson.title, uiFriendlyMessageJson.body)));
        options.errors && (this.errors = options.errors.map((error) => new Error(error)));
        options.syncStarted && (this.syncStarted = new Date(options.syncStarted));
        options.syncEnd && (this.syncEnd = new Date(options.syncEnd));
        options.customData && (this.customData = options.customData);
    }

    public toJSON(): IStepSyncProgressJSON {
        return Object.assign({}, this, {
            syncType: this.syncType,
            uiFriendlyMessages: this.uiFriendlyMessages.map((uiFriendlyMessage) => { return uiFriendlyMessage.toJSON() }),
            errors: this.errors.map((error) => { return error.toString() }),
            syncStarted: this.syncStarted?.toISOString(),
            syncEnd: this.syncEnd?.toISOString(),
            customData: this.customData,
        });
    }
}
